var exports = {};

exports = function (hljs) {
  var VERSION = "HTTP/[0-9\\.]+";
  return {
    aliases: ["https"],
    illegal: "\\S",
    contains: [{
      begin: "^" + VERSION,
      end: "$",
      contains: [{
        className: "number",
        begin: "\\b\\d{3}\\b"
      }]
    }, {
      begin: "^[A-Z]+ (.*?) " + VERSION + "$",
      returnBegin: true,
      end: "$",
      contains: [{
        className: "string",
        begin: " ",
        end: " ",
        excludeBegin: true,
        excludeEnd: true
      }, {
        begin: VERSION
      }, {
        className: "keyword",
        begin: "[A-Z]+"
      }]
    }, {
      className: "attribute",
      begin: "^\\w",
      end: ": ",
      excludeEnd: true,
      illegal: "\\n|\\s|=",
      starts: {
        end: "$",
        relevance: 0
      }
    }, {
      begin: "\\n\\n",
      starts: {
        subLanguage: [],
        endsWithParent: true
      }
    }]
  };
};

export default exports;